import React, {useState} from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {IBlog, BlogType} from "../models/blog";
import Img from "gatsby-image";
import getFormattedDate from "../utils/getFormattedDate";
import ArticleCard from "../components/article-card";

const Tab = ({
  title,
  isSelected,
  onSelect,
}: {
  title: string;
  isSelected: boolean;
  onSelect: () => void;
}) => (
  <li
    className={`${
      isSelected ? "border-b-4 border-solid border-primary " : ""
    } pb-1 cursor-pointer font-bold  md:text-2xl`}
    onClick={() => onSelect()}
  >
    {title}
  </li>
);

const BlogPage = ({data}) => {
  const [activeType, setActiveType] = useState<BlogType>("Posts");

  const articles = data.allStrapiBlogs.nodes as IBlog[];

  return (
    <Layout>
      <SEO title="Blog" description="Latest News and Resources" />
      <div className="py-10">
        <ul className="flex w-full max-w-xs justify-between mb-4 mx-auto md:mx-0">
          <Tab
            key="Posts"
            title="Posts"
            isSelected={activeType === "Posts"}
            onSelect={() => setActiveType("Posts")}
          />
          <Tab
            key="Resources"
            title="Resources"
            isSelected={activeType === "Resources"}
            onSelect={() => setActiveType("Resources")}
          />
          <Tab
            key="News"
            title="News"
            isSelected={activeType === "News"}
            onSelect={() => setActiveType("News")}
          />
        </ul>

        <div className="flex flex-col">
          {articles
            .filter((article) => article.type === activeType)
            .map((article) => (
              <ArticleCard article={article} />
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query getBlog {
    allStrapiBlogs(
      sort: {order: [DESC, DESC], fields: [createdOn, createdAt]}
      filter: {published: {eq: true}}
    ) {
      nodes {
        slug
        title
        externalLink
        type
        published
        content
        createdAt
        createdOn
        ogImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
